import React, { useRef, useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './SynchronizedImageViewer.css';

const SynchronizedImageViewer = ({ images }) => {
	const [scale, setScale] = useState(1);
	const [positionX, setPositionX] = useState(0);
	const [positionY, setPositionY] = useState(0);
	const wrappers = [useRef(null), useRef(null), useRef(null)];

	const syncTransform = (scale, positionX, positionY) => {
		if (isNaN(scale) || isNaN(positionX) || isNaN(positionY)) return;
		wrappers.forEach((wrapper) => {
			if (wrapper.current) {
				wrapper.current.setTransform(positionX, positionY, scale);
			}
		});
	};

	const handleSync = ({ scale, positionX, positionY }) => {
		setScale(scale);
		setPositionX(positionX);
		setPositionY(positionY);
		syncTransform(scale, positionX, positionY);
	};

	useEffect(() => {
		syncTransform(scale, positionX, positionY);
	}, [scale, positionX, positionY]);

	return (
		<div className="synchronized-image-viewer">
			{images.map((imageSrc, index) => (
				<div key={index} className="image-container">
					<TransformWrapper
						ref={wrappers[index]}
						onZoomStop={({ state }) => handleSync(state)}
						onPanningStop={({ state }) => handleSync(state)}
						initialScale={1}
						initialPositionX={0}
						initialPositionY={0}
					>
						{() => (
							<TransformComponent>
								<img src={imageSrc} alt={`Comparison ${index + 1}`} className="synchronized-image" />
							</TransformComponent>
						)}
					</TransformWrapper>
				</div>
			))}
		</div>
	);
};

export default SynchronizedImageViewer;
