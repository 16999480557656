import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../../ressources/chanel_logo.png';

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selected, setSelected] = useState(location.pathname);

	useEffect(() => {
		setSelected(location.pathname);
	}, [location]);

	const handleReset = () => {
		window.location.reload();
	};

	return (
		<header className="header">
			<div className="header-left">
				<img
					src={logo}
					alt="Logo"
					className="logo"
					onClick={handleReset}
					title="Reset Application"
				/>
				<h1>Conformia – BAG</h1>
			</div>
			<div className="team-toggle">
				<button
					className={`toggle-button ${selected === '/equipe-idp' ? 'active' : ''}`}
					onClick={() => navigate('/equipe-idp')}
				>
					IDP
				</button>
				<button
					className={`toggle-button ${selected === '/equipe-pep' ? 'active' : ''}`}
					onClick={() => navigate('/equipe-pep')}
				>
					PEP
				</button>
			</div>
		</header>
	);
};

export default Header;
