// SharedToolbar.jsx
import React from "react";
import PropTypes from "prop-types";
import select_zone_icon from "../../ressources/select_zone_icon.png";
import select_barcode_icon from "../../ressources/select_barcode_icon.png";
import center_icon from "../../ressources/center_icon.png";
import move_icon from "../../ressources/move_icon.png";
import bin_icon from "../../ressources/bin_icon.png";
import "./SharedToolbar.css";

const SharedToolbar = ({
	option,
	onOptionChange,
	onClear,
	isLocked,
	onToggleLock,
	onResetZoom
}) => {
	return (
		<div className="toolbar">
			<button
				className={!isLocked ? "btn active" : "btn"}
				onClick={onToggleLock}
			>
				<img 
					src={move_icon} 
					alt="Activer/Désactiver le déplacement" 
					style={{ transform: 'scaleX(-1)' }}
				/>
			</button>

			<button
				className={option === "rect" ? "btn active" : "btn"}
				onClick={() => onOptionChange("rect")}
			>
				<img src={select_zone_icon} alt="Selectionner une zone" />
			</button>

			<button
				className={option === "barcodeRect" ? "btn active" : "btn"}
				onClick={() => onOptionChange("barcodeRect")}
			>
				<img src={select_barcode_icon} alt="Selectionner un code barre" />
			</button>

			<div className="toolbar-separator"></div>

			<button className="btn" onClick={onClear}>
				<img src={bin_icon} alt="Reinitialiser" />
			</button>

			<button className="btn" onClick={onResetZoom}>
				<img src={center_icon} alt="Réinitialiser le zoom" />
			</button>
		</div>
	);
};

SharedToolbar.propTypes = {
	option: PropTypes.string.isRequired,
	onOptionChange: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	isLocked: PropTypes.bool.isRequired,
	onToggleLock: PropTypes.func.isRequired,
	onResetZoom: PropTypes.func.isRequired
};

export default SharedToolbar;