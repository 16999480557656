import React from 'react';
import "./PageSelect.css";
import { HiChevronDown } from 'react-icons/hi';

const PageSelect = ({ pageCount, onPageChange, selectedPage }) => {
	const pages = Array.from({ length: pageCount }, (_, i) => `${i + 1}`);

	return (
		<div className="page-select-container">
			<label className='pageSelect' htmlFor="page-select">Page :</label>
			<div style={{ position: 'relative', display: 'inline-block' }}>
				<select
					id="page-select"
					value={selectedPage}
					onChange={(e) => onPageChange(parseInt(e.target.value, 10))}
				>
					{pages.map((page, index) => (
						<option key={index} value={index}>
							{page}
						</option>
					))}
				</select>
				<span style={{
					position: 'absolute',
					right: '10px',
					top: '50%',
					transform: 'translateY(-50%)',
					pointerEvents: 'none'
				}}>
					<HiChevronDown size={20} />
				</span>
			</div>
		</div>
	);
};

export default PageSelect;
