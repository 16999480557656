import React from 'react';
import './BarcodeGrade.css';

const BarcodeGrade = ({ imgSrc, grade, number }) => {
	const getGradeColor = (grade) => {
		if (['A', 'B', 'C'].includes(grade)) {
			return 'green';
		} else if (['D', 'E', 'F'].includes(grade)) {
			return 'orange';
		}
		return 'black';
	};

	return (
		<div className="barcode-grade">
			<div className="barcode-grade-text" style={{ color: getGradeColor(grade) }}>
				Grade: {grade || 'N/A'}<br />
				{/* {number && <span>Code-barres: {number}</span>} */}
			</div>
		</div>
	);
};

export default BarcodeGrade;
