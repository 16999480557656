import React, { useState, useEffect, useRef } from 'react';
import FileDropzone from '../FileDropZone/FileDropZone';
import PageSelect from '../PageSelect/PageSelect';
import LayerList from '../LayerList/LayerList';
import Canvas from '../Canvas/Canvas';
import SynchronizedImageViewer from '../SynchronizedImageViewer/SynchronizedImageViewer';
import BarcodeGrade from '../BarcodeGrade/BarcodeGrade';
import {
	getPageCount,
	processPdf,
	pdfToImage,
	extractImagesFromPdf,
	overlayAndHighlightErrors,
	gradeBarcode,
	groupedErrorTiles,
	compare_images
} from '../../api/api_call';
import jsPDF from 'jspdf';
import './DisplayPDF.css';
import SharedToolbar from '../SharedToolbar/SharedToolbar';
import zoom_icon from "../../ressources/zoom_icon.png";
import dezoom_icon from "../../ressources/dezoom_icon.png";
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import Modal from '../Modal/Modal';

/* global cv */ // Informez ESLint que `cv` est une variable globale

const DisplayPDF = ({ mode }) => {
	const [pdfFile1, setPdfFile1] = useState(null);
	const [pdfFile2, setPdfFile2] = useState(null);
	const [pageCount1, setPageCount1] = useState(0);
	const [pageCount2, setPageCount2] = useState(0);
	const [selectedPage1, setSelectedPage1] = useState(0);
	const [selectedPage2, setSelectedPage2] = useState(0);
	const [image1, setImage1] = useState(null);
	const [image2, setImage2] = useState(null);
	const [layerConfigs1, setLayerConfigs1] = useState([]);
	const [layerConfigs2, setLayerConfigs2] = useState([]);
	const [selectedLayers1, setSelectedLayers1] = useState([]);
	const [selectedLayers2, setSelectedLayers2] = useState([]);
	const [barcodeGrades, setBarcodeGrades] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showComparison, setShowComparison] = useState(false);
	const [comparisonResults, setComparisonResults] = useState([]);
	const [barcodeResult, setBarcodeResult] = useState(null); // Nouvel état pour le résultat du code-barres
	const canvasRef1 = useRef(null);
	const canvasRef2 = useRef(null);
	const [borderColors, setBorderColors] = useState({});
	const [selectedOptions, setSelectedOptions] = useState({});
	const [PDFfileName, setPDFfileName] = useState("");
	const [progress, setProgress] = useState(0);
	const currentRequestId1 = useRef(0); // Pour fetchImage1
	const currentRequestId2 = useRef(0); // Pour fetchImage2
	const [option, setOption] = useState("rect");
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [zoom, setZoom] = useState({
		canvas1: {
			scale: 1,
			positionX: 0,
			positionY: 0
		},
		canvas2: {
			scale: 1,
			positionX: 0,
			positionY: 0
		}
	});
	const [isLocked, setIsLocked] = useState(true);

	const handleZoomIn = (canvasId) => {
		setZoom(prev => ({
			...prev,
			[canvasId]: {
				...prev[canvasId],
				scale: prev[canvasId].scale * 1.2
			}
		}));
	};

	const handleZoomOut = (canvasId) => {
		setZoom(prev => ({
			...prev,
			[canvasId]: {
				...prev[canvasId],
				scale: prev[canvasId].scale / 1.2
			}
		}));
	};

	const handleTransformChange = (canvasId, transform) => {
		setZoom(prev => ({
			...prev,
			[canvasId]: transform
		}));
	};

	const isDefaultChecked = (layerText) => {
		return (
			!layerText.startsWith("VERNIS") &&
			!layerText.startsWith("PELLICULAGE") &&
			!layerText.startsWith("BRILLANT") &&
			!layerText.startsWith("GAUFRAGE")
		);
	};

	useEffect(() => {
		const fetchPageCount1 = async () => {
			if (pdfFile1) {
				const pageCount = await getPageCount(pdfFile1);
				setPageCount1(pageCount);
			}
		};
		fetchPageCount1();
	}, [pdfFile1]);

	useEffect(() => {
		const fetchPageCount2 = async () => {
			if (pdfFile2) {
				const pageCount = await getPageCount(pdfFile2);
				setPageCount2(pageCount);
			}
		};
		fetchPageCount2();
	}, [pdfFile2]);

	useEffect(() => {
		const fetchLayerConfigs1 = async () => {
			if (pdfFile1) {
				try {
					const configs = await processPdf(pdfFile1);
					setLayerConfigs1(configs);
					const layersToDisable = configs
						.filter((layer) => !isDefaultChecked(layer.text))
						.map((layer) => layer.text);
					setSelectedLayers1(layersToDisable);
				} catch (error) {
					console.error('Error processing PDF:', error);
					setShowErrorModal(true);
				}
			}
		};

		fetchLayerConfigs1();
	}, [pdfFile1]);


	useEffect(() => {
		const fetchLayerConfigs2 = async () => {
			if (pdfFile2) {
				const configs = await processPdf(pdfFile2);
				setLayerConfigs2(configs);
				setSelectedLayers2(
					configs.filter((layer) => !isDefaultChecked(layer.text)).map((layer) => layer.text)
				);
			}
		};
		fetchLayerConfigs2();
	}, [pdfFile2]);

	useEffect(() => {
		const fetchImage1 = async () => {
			if (pdfFile1 && selectedPage1 >= 0) {
				currentRequestId1.current++; // Incrémente le requestId1
				const requestId = currentRequestId1.current;

				const layersToDisable = selectedLayers1 || [];

				const imageUrl = await pdfToImage(pdfFile1, selectedPage1, layersToDisable);

				if (requestId === currentRequestId1.current) {
					setImage1(imageUrl); // Mettre à jour l'image seulement si c'est la requête la plus récente
				}
			}
		};

		fetchImage1();
	}, [pdfFile1, selectedPage1, selectedLayers1]);


	useEffect(() => {
		const fetchImage2 = async () => {
			if (pdfFile2 && selectedPage2 >= 0) {
				currentRequestId2.current++; // Incrémente le requestId2
				const requestId = currentRequestId2.current;

				const layersToDisable = selectedLayers2 || [];

				const imageUrl = await pdfToImage(pdfFile2, selectedPage2, layersToDisable);

				if (requestId === currentRequestId2.current) {
					setImage2(imageUrl); // Mettre à jour la deuxième image seulement si c'est la requête la plus récente
				}
			}
		};

		fetchImage2();
	}, [pdfFile2, selectedPage2, selectedLayers2]);

	const resetBorders = () => {
		setBorderColors({}); // Réinitialiser toutes les bordures par défaut (transparent)
	};

	const handleLayerToggle1 = (layer) => {
		setSelectedLayers1((prevSelectedLayers) =>
			prevSelectedLayers.includes(layer)
				? prevSelectedLayers.filter((l) => l !== layer)
				: [...prevSelectedLayers, layer]
		);
	};

	const handleLayerToggle2 = (layer) => {
		setSelectedLayers2((prevSelectedLayers) =>
			prevSelectedLayers.includes(layer)
				? prevSelectedLayers.filter((l) => l !== layer)
				: [...prevSelectedLayers, layer]
		);
	};

	const getDisabledLayers = (layerConfigs, selectedLayers) => {
		return layerConfigs
			.filter((layer) => selectedLayers.includes(layer.text))
			.map((layer) => layer.text);
	};

	const handleAnnotation = (resultIndex, tileIndex, annotation) => {
		let color;
		switch (annotation) {
			case "ignored":
				color = "#d3d3d3"; // Gris doux
				break;
			case "acceptable":
				color = "#ffa500"; // Orange plus doux
				break;
			case "non-acceptable":
				color = "#ff6961"; // Rouge doux
				break;
			default:
				color = "transparent"; // Rendre la bordure invisible par défaut
		}

		setBorderColors((prevState) => ({
			...prevState,
			[`${resultIndex}-${tileIndex}`]: color // Mise à jour couleur par resultIndex et tileIndex
		}));

		setSelectedOptions((prevOptions) => ({
			...prevOptions,
			[`${resultIndex}-${tileIndex}`]: annotation
		}));
	};

	const convertImageToRGB = async (imageUrl) => {
		await window.cvReadyPromise; // Attendre que OpenCV soit prêt
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.onload = () => {
				let src = cv.imread(img);
				let dst = new cv.Mat();
				cv.cvtColor(src, dst, cv.COLOR_BGR2RGB);
				cv.imshow('canvasOutput', dst);
				src.delete();
				dst.delete();
				resolve(document.getElementById('canvasOutput').toDataURL());
			};
			img.src = imageUrl;
		});
	};

	const truncateFileName = (fileName, maxLength = 20) => {
		if (fileName.length > maxLength) {
			return fileName.slice(0, maxLength) + "...";
		}
		return fileName;
	};

	// Fonction pour déterminer si le rectangle est grand
	const isRectangleLarge = (rect, canvasDimensions) => {
		const canvasArea = canvasDimensions.width * canvasDimensions.height;
		const rectArea = rect.width * rect.height;
		const areaFraction = rectArea / canvasArea;

		return areaFraction > 0.4; // Rectangle couvre plus de 40% de la zone du canvas
	};

	const handleCompareAndGrade = async () => {
		try {
			setIsLoading(true);
			setProgress(0);

			// Disable all interactive elements
			const interactiveElements = document.querySelectorAll('button, input, select');
			interactiveElements.forEach(element => {
				element.disabled = true;
			});

			// Reset states
			setBarcodeGrades(null);
			setBarcodeResult(null);
			resetBorders();
			setShowComparison(false);
			setComparisonResults([]);

			// Get coordinates and validate
			const coords1 = canvasRef1.current.getRectCoordinates();
			const coords2 = canvasRef2.current.getRectCoordinates();
			const canvasDimensions1 = canvasRef1.current.getCanvasDimensions();
			const canvasDimensions2 = canvasRef2.current.getCanvasDimensions();
			
			// Determine comparison method for PEP mode
			let useCompareImages = false;
			if (mode === 'PEP' && coords1.length === 0 && coords2.length === 0) {
				useCompareImages = true;
				coords1.push({
					left: 0,
					top: 0,
					width: canvasDimensions1.width,
					height: canvasDimensions1.height,
					scale: 1,
					isBarcode: false,
				});
				coords2.push({
					left: 0,
					top: 0,
					width: canvasDimensions2.width,
					height: canvasDimensions2.height,
					scale: 1,
					isBarcode: false,
				});
			}

			// Validate rectangle count
			if (coords1.length !== coords2.length) {
				throw new Error('Le nombre de rectangles doit être le même de chaque côté.');
			}

			// Get disabled layers
			const disabledLayers1 = getDisabledLayers(layerConfigs1, selectedLayers1);
			const disabledLayers2 = getDisabledLayers(layerConfigs2, selectedLayers2);

			// Calculate progress steps
			const totalRectangles = coords1.length;
			const stepsPerRectangle = 6; // Nombre maximum d'étapes par rectangle
			const totalSteps = totalRectangles * stepsPerRectangle;
			const percentagePerStep = 100 / totalSteps;
	
			const newResults = [];
	
			for (let i = 0; i < coords1.length; i++) {
				// Step 1 : Extract images
				const [img1, img2] = await extractImagesFromPdf(
					pdfFile1, pdfFile2, coords1[i], coords2[i],
					selectedPage1, selectedPage2,
					disabledLayers1, disabledLayers2
				);
				setProgress(prev => prev + percentagePerStep);
	
				// Step 2 : handle barcode
				const isBarcode = coords1[i].isBarcode;
				if (isBarcode) {
					const grades = await gradeBarcode(img1, img2);
					if (grades) {
						setBarcodeGrades(grades);
					}
				}
				setProgress(prev => prev + percentagePerStep);
	
				// Step 3 : overlay and compare documents
				let result = {};
				let errorsRGBUrl;
				if (mode === 'PEP' && useCompareImages) {
					// PEP mode with compare_images
					const result_pixel_compare = await compare_images(img1, img2);
					setProgress(prev => prev + percentagePerStep);
	
					// Step 4 : Generate error tiles
					const tiles = await groupedErrorTiles(
						result_pixel_compare.threshUrl,
						result_pixel_compare.resultUrl,
						img1,
						img2
					);
					setProgress(prev => prev + percentagePerStep);
	
					result = {
						img1: img1,
						img2: img2,
						errorsRGBUrl: result_pixel_compare.resultUrl,
						tiles: tiles,
						isBarcode: isBarcode,
						isSingleLargeRectangle: coords1.length <= 2 && !isBarcode && isRectangleLarge(coords1[i], canvasDimensions1)
					};
	
					setProgress(prev => prev + percentagePerStep);
	
				} else {
					// IDP mode or PEP mode with overlayAndHighlightErrors
					const overlayResult = await overlayAndHighlightErrors(
						img1,
						img2,
						0.5,
					);
					setProgress(prev => prev + percentagePerStep);
	
					// Step 4 : Convert to RGB image
					errorsRGBUrl = await convertImageToRGB(overlayResult.outputImageUrl);
					setProgress(prev => prev + percentagePerStep);
	
					// Step 5 : Generate error tiles
					const tiles = await groupedErrorTiles(
						overlayResult.threshImageUrl,
						errorsRGBUrl,
						overlayResult.transformedImage1Url,
						overlayResult.transformedImage2Url
					);
					setProgress(prev => prev + percentagePerStep);
	
					result = {
						img1: overlayResult.transformedImage1Url,
						img2: overlayResult.transformedImage2Url,
						errorsRGBUrl: errorsRGBUrl,
						tiles: tiles,
						isBarcode: isBarcode,
						isSingleLargeRectangle: coords1.length <= 2 && !isBarcode && isRectangleLarge(coords1[i], canvasDimensions1)
					};
				}
	
				// Step 6 : update results
				if (isBarcode) {
					setBarcodeResult(result);
				} else {
					newResults.push(result);
				}
				setProgress(prev => prev + percentagePerStep);
			}
	
			setComparisonResults(newResults);
			setShowComparison(true);
			setProgress(100);

			// Reset all tool selections after results are shown
			setOption("");
			setIsLocked(true);

			// Scroll to results
			setTimeout(() => {
				window.scrollTo({
					top: window.innerHeight,
					behavior: "smooth",
				});
			}, 1000);
		} catch (error) {
			console.error('An error occurred during comparison and grading:', error);
			setShowErrorModal(true);
		} finally {
			setIsLoading(false);
			// Re-enable all interactive elements
			const interactiveElements = document.querySelectorAll('button, input, select');
			interactiveElements.forEach(element => {
				element.disabled = false;
			});
		}
	};

	const generatePDF = async () => {
		if (
			(!comparisonResults || comparisonResults.length === 0) &&
			!barcodeResult &&
			!barcodeGrades
		) {
			alert("Aucun résultat à inclure dans le rapport.");
			return;
		}

		const doc = new jsPDF();

		// Définir la police à "helvetica"
		doc.setFont("helvetica");

		// Page de garde
		doc.setFillColor(240, 240, 240); // Fond gris clair
		doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, "F"); // Remplir la page
		doc.setFontSize(30);
		doc.setTextColor(0, 0, 0);
		doc.setFont("helvetica", "bold");
		doc.text("ConformIA - BAG", doc.internal.pageSize.width / 2, 60, null, null, "center");

		doc.setFontSize(24);
		doc.setFont("helvetica", "normal");
		doc.text("Rapport d'analyse des anomalies", doc.internal.pageSize.width / 2, 100, null, null, "center");

		doc.setFontSize(18);
		doc.setTextColor(100);
		doc.text("CHANEL", doc.internal.pageSize.width / 2, 140, null, null, "center");

		doc.setFontSize(12);
		doc.setTextColor(50);
		const date = new Date();
		const formattedDate = `${("0" + date.getDate()).slice(-2)}/${(
			"0" +
			(date.getMonth() + 1)
		).slice(-2)}/${date.getFullYear()}`;
		doc.text("Généré par ConformIA - BAG", doc.internal.pageSize.width / 2, 180, null, null, "center");
		doc.text(`${formattedDate}`, doc.internal.pageSize.width / 2, 200, null, null, "center");

		// Add the PDF filenames
		doc.setFontSize(10);
		doc.setTextColor(80);
		const file1Name = pdfFile1 ? truncateFileName(pdfFile1.name, 50) : "Document 1";
		const file2Name = pdfFile2 ? truncateFileName(pdfFile2.name, 50) : "Document 2";
		doc.text("Documents comparés :", doc.internal.pageSize.width / 2, 230, null, null, "center");
		doc.text(`${file1Name}`, doc.internal.pageSize.width / 2, 240, null, null, "center");
		doc.text(`${file2Name}`, doc.internal.pageSize.width / 2, 250, null, null, "center");

		doc.addPage();

		let yOffset = 20; // Position verticale initiale
		const margin = 10;
		const spacing = 10;
		const pageWidth = doc.internal.pageSize.width;
		const maxImageHeight = 60; // Hauteur maximale des images
		const maxImageWidth = (pageWidth - 2 * margin - 2 * spacing) / 3; // Largeur maximale par image

		doc.setFontSize(18);
		doc.setTextColor(40, 40, 40);

		const categories = {
			"non-acceptable": [],
			acceptable: [],
			ignored: []
		};

		// Organiser les résultats en fonction des annotations
		comparisonResults.forEach((result, resultIndex) => {
			if (!result.isSingleLargeRectangle) {
				const option = selectedOptions[`${resultIndex}-0`];
				if (option) {
					categories[option].push({
						type: "comparison",
						img1: result.img1,
						img2: result.img2,
						errorsRGBUrl: result.errorsRGBUrl
					});
				}
			} else {
				result.tiles.forEach((tile, tileIndex) => {
					const option = selectedOptions[`${resultIndex}-${tileIndex}`];
					if (option) {
						categories[option].push({
							type: "tile",
							tile: tile
						});
					}
				});
			}
		});

		// Inclure le résultat du code-barres séparément
		if (barcodeResult && barcodeGrades) {
			categories["barcode"] = {
				result: barcodeResult,
				grades: barcodeGrades
			};
		}

		// Vérifier s'il y a des annotations
		const hasAnnotations =
			Object.keys(categories).some((key) => {
				return key === "barcode" || categories[key].length > 0;
			});

		if (!hasAnnotations) {
			alert("Veuillez annoter au moins une image pour générer le rapport.");
			return;
		}

		// Fonction pour charger une image et obtenir ses dimensions
		const loadImage = (imageUrl, maxWidth, maxHeight) => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = function () {
					let width = img.width;
					let height = img.height;

					// Redimensionner l'image en conservant le ratio d'aspect
					const widthRatio = maxWidth / width;
					const heightRatio = maxHeight / height;
					const ratio = Math.min(widthRatio, heightRatio, 1); // Assurer que le ratio <= 1

					width = width * ratio;
					height = height * ratio;

					resolve({ img, width, height });
				};
				img.onerror = reject;
				img.src = imageUrl;
			});
		};

		// Inclure les grades des code-barres si disponibles
		if (categories["barcode"]) {
			const { result, grades } = categories["barcode"];
			doc.setFontSize(18);
			doc.setFont("helvetica", "bold"); // Titres en gras
			doc.setTextColor(40, 40, 40);
			doc.text("Résultat code-barres", margin, yOffset);
			yOffset += 10;

			doc.setFontSize(14);
			doc.setFont("helvetica", "normal");
			// Affichage des grades comme demandé
			const truncatedFile1 = truncateFileName(pdfFile1 ? pdfFile1.name : "Document 1");
			const truncatedFile2 = truncateFileName(pdfFile2 ? pdfFile2.name : "Document 2");
			doc.text(`Grade code-barres ${truncatedFile1} : ${grades.grade1}`, margin, yOffset);
			yOffset += 10;
			doc.text(`Grade code-barres ${truncatedFile2} : ${grades.grade2}`, margin, yOffset);
			yOffset += 10;

			// Inclure les images des code-barres
			if (yOffset + maxImageHeight + 20 > doc.internal.pageSize.height) {
				doc.addPage();
				yOffset = margin;
			}

			// Charger et ajouter les images en les centrant
			const imagesToAdd = [result.img1, result.errorsRGBUrl, result.img2];
			const imageTitles = [
				truncateFileName(pdfFile1 ? pdfFile1.name : "Document 1"),
				"Superposition",
				truncateFileName(pdfFile2 ? pdfFile2.name : "Document 2")
			];

			const loadedImages = await Promise.all(
				imagesToAdd.map((url) => loadImage(url, maxImageWidth, maxImageHeight))
			);

			// Calculer la largeur totale des images
			const totalImagesWidth =
				loadedImages.reduce((sum, img) => sum + img.width, 0) +
				spacing * (loadedImages.length - 1);

			// Marge gauche pour centrer les images
			const leftMargin = margin + ((pageWidth - 2 * margin) - totalImagesWidth) / 2;

			// Ajouter les titres au-dessus de chaque image
			let x = leftMargin;
			doc.setFontSize(12);
			doc.setTextColor(0, 0, 0);
			for (let i = 0; i < loadedImages.length; i++) {
				const img = loadedImages[i];
				const title = imageTitles[i];
				const textWidth = doc.getTextWidth(title);
				const textX = x + (img.width - textWidth) / 2;
				doc.text(title, textX, yOffset);
				x += img.width + spacing;
			}
			yOffset += 6; // Déplacer yOffset après les titres

			// Ajouter les images
			x = leftMargin;
			let maxImageHeightUsed = 0;
			for (const img of loadedImages) {
				doc.addImage(img.img, "JPEG", x, yOffset, img.width, img.height);
				x += img.width + spacing;
				if (img.height > maxImageHeightUsed) {
					maxImageHeightUsed = img.height;
				}
			}
			yOffset += maxImageHeightUsed + 20;
		}

		// Fonction pour ajouter une section d'images dans le PDF
		const addImagesToPDF = async (categoryTitle, images) => {
			if (images.length > 0) {
				doc.setFontSize(18);
				doc.setFont("helvetica", "bold"); // Titres en gras
				doc.setTextColor(40, 40, 40);
				doc.text(categoryTitle, margin, yOffset);
				yOffset += 10;

				for (let i = 0; i < images.length; i++) {
					const item = images[i];

					if (yOffset + maxImageHeight + 20 > doc.internal.pageSize.height) {
						doc.addPage();
						yOffset = margin;
					}

					// Ajouter "Anomalie N"
					doc.setFontSize(14);
					doc.setFont("helvetica", "normal");
					doc.text(`Anomalie ${i + 1}`, margin, yOffset);
					yOffset += 10;

					// Charger et ajouter les images en les centrant
					let imagesToAdd = [];
					let imageTitles = [];
					if (item.type === "comparison") {
						imagesToAdd = [item.img1, item.errorsRGBUrl, item.img2];
						imageTitles = [
							truncateFileName(pdfFile1 ? pdfFile1.name : "Document 1"),
							"Superposition",
							truncateFileName(pdfFile2 ? pdfFile2.name : "Document 2")
						];
					} else if (item.type === "tile") {
						imagesToAdd = [
							`data:image/png;base64,${item.tile.image1_tile}`,
							`data:image/png;base64,${item.tile.tile}`,
							`data:image/png;base64,${item.tile.transformed_image2_tile}`
						];
						imageTitles = [
							truncateFileName(pdfFile1 ? pdfFile1.name : "Document 1"),
							"Superposition",
							truncateFileName(pdfFile2 ? pdfFile2.name : "Document 2")
						];
					}

					const loadedImages = await Promise.all(
						imagesToAdd.map((url) => loadImage(url, maxImageWidth, maxImageHeight))
					);

					const totalImagesWidth =
						loadedImages.reduce((sum, img) => sum + img.width, 0) +
						spacing * (loadedImages.length - 1);
					const leftMargin = margin + ((pageWidth - 2 * margin) - totalImagesWidth) / 2;

					// Ajouter les titres au-dessus de chaque image
					let x = leftMargin;
					doc.setFontSize(12);
					doc.setTextColor(0, 0, 0);
					for (let j = 0; j < loadedImages.length; j++) {
						const img = loadedImages[j];
						const title = imageTitles[j];
						const textWidth = doc.getTextWidth(title);
						const textX = x + (img.width - textWidth) / 2;
						doc.text(title, textX, yOffset);
						x += img.width + spacing;
					}
					yOffset += 6; // Déplacer yOffset après les titres

					// Ajouter les images
					x = leftMargin;
					let maxImageHeightUsed = 0;
					for (const img of loadedImages) {
						doc.addImage(img.img, "JPEG", x, yOffset, img.width, img.height);
						x += img.width + spacing;
						if (img.height > maxImageHeightUsed) {
							maxImageHeightUsed = img.height;
						}
					}
					yOffset += maxImageHeightUsed + 20; // Ajouter un espace supplémentaire
				}
			}
		};

		// Ajouter les sections
		await addImagesToPDF("Anomalies non-acceptables", categories["non-acceptable"]);
		await addImagesToPDF("Anomalies acceptables", categories["acceptable"]);
		await addImagesToPDF("Images ignorées", categories["ignored"]);

		// Enregistrer le PDF avec le nom spécifié
		const outputFileName = `Rapport_Anomalies_BAG_${PDFfileName}.pdf`;
		doc.save(outputFileName);
	};

	const handleRemovePDF1 = () => {
		setPdfFile1(null);
		setPageCount1(0);
		setSelectedPage1(0);
		setImage1(null);
		setLayerConfigs1([]);
		setSelectedLayers1([]);
		// Reset comparison states
		setShowComparison(false);
		setComparisonResults([]);
		setBarcodeGrades(null);
		setBarcodeResult(null);
		resetBorders();
	};

	const handleRemovePDF2 = () => {
		setPdfFile2(null);
		setPageCount2(0);
		setSelectedPage2(0);
		setImage2(null);
		setLayerConfigs2([]);
		setSelectedLayers2([]);
		// Reset comparison states
		setShowComparison(false);
		setComparisonResults([]);
		setBarcodeGrades(null);
		setBarcodeResult(null);
		resetBorders();
	};

	const handleOptionChange = (newOption) => {
		// If clicking the same option, deselect it
		if (option === newOption) {
			setOption(""); // Clear the selection
		} else {
			setOption(newOption);
			// If selecting a drawing tool, ensure movement is locked
			if (newOption === "rect" || newOption === "barcodeRect") {
				setIsLocked(true);
			}
		}
	};

	const handleClear = () => {
		if (canvasRef1.current) {
			canvasRef1.current.handleClear();
		}
		if (canvasRef2.current) {
			canvasRef2.current.handleClear();
		}
	};

	const handleToggleLock = () => {
		setIsLocked(!isLocked);
		// When enabling movement (unlocking), clear other tool selections
		if (isLocked) {
			setOption("move");
		} else {
			setOption(""); // Clear selection when locking
		}
	};

	const handleResetZoom = () => {
		if (canvasRef1.current) {
			canvasRef1.current.resetTransform();
		}
		if (canvasRef2.current) {
			canvasRef2.current.resetTransform();
		}
	};

	// Add this helper function to check if all results are annotated
	const areAllResultsAnnotated = () => {
		return true; // TODO: Fix this function

		// If there are no results to annotate, return false
		if ((!comparisonResults || comparisonResults.length === 0) && !barcodeResult) {
			return false;
		}

		// Check comparison results
		if (comparisonResults && comparisonResults.length > 0) {
			return comparisonResults.every((result, resultIndex) => {
				if (result.tiles && result.tiles.length > 0) {
					// Check if all tiles are annotated
					return result.tiles.every((_, tileIndex) => {
						const key = `${resultIndex}-${tileIndex}`;
						return borderColors[key] && borderColors[key] !== "transparent";
					});
				} else {
					return true;
				}
			});
		}

		return true;
	};

	return (
		<div className="pdf-comparison">
			<div className={`dropzones ${pdfFile1 ? "loaded" : "centered"}`}>
				{/* Zone pour le premier PDF */}
				<div className="dropzone-wrapper">
					{!pdfFile1 && <FileDropzone onDrop={setPdfFile1} label={mode === "IDP" ? "Document de décor" : ""} />}

					{pdfFile1 && (
						<div className="file-details">
							<div className="canvas-wrapper">
								{/* Affichage du nom du fichier */}
								<p className="file-name">{truncateFileName(pdfFile1.name)}</p>
								<div className="canvas-section">
									{image1 && <Canvas
										ref={canvasRef1}
										imgSrc={image1}
										option={option}
										scale={zoom.canvas1.scale}
										positionX={zoom.canvas1.positionX}
										positionY={zoom.canvas1.positionY}
										onTransformChange={(transform) => handleTransformChange('canvas1', transform)}
										isLocked={isLocked}
									/>}
								</div>
							</div>

							<div className="controls-section">
								<button className="removeButton" onClick={handleRemovePDF1}>
									Retirer Document
								</button>

								{pageCount1 > 0 && (
									<PageSelect
										pageCount={pageCount1}
										selectedPage={selectedPage1}
										onPageChange={setSelectedPage1}
									/>
								)}
								<LayerList
									layers={layerConfigs1}
									selectedLayers={selectedLayers1}
									onLayerToggle={handleLayerToggle1}
									onLayersChange={setSelectedLayers1}
									showLayers={!!pdfFile1}
								/>

								<div className="zoom-controls">
									<button className="zoom-button" onClick={() => handleZoomIn('canvas1')}>
										<img src={zoom_icon} alt="Zoom in" />
									</button>
									<button className="zoom-button" onClick={() => handleZoomOut('canvas1')}>
										<img src={dezoom_icon} alt="Zoom out" />
									</button>
								</div>
							</div>
						</div>
					)}
				</div>

				{/* Zone pour le second PDF */}
				<div className="dropzone-wrapper">
					{!pdfFile2 && <FileDropzone onDrop={setPdfFile2} label={mode === "IDP" ? "Document BAG" : ""} />}

					{pdfFile2 && (
						<div className="file-details">
							<div className="canvas-wrapper">
								{/* Affichage du nom du fichier */}
								<p className="file-name">{truncateFileName(pdfFile2.name)}</p>
								<div className="canvas-section">
									{image2 && <Canvas
										ref={canvasRef2}
										imgSrc={image2}
										option={option}
										scale={zoom.canvas2.scale}
										positionX={zoom.canvas2.positionX}
										positionY={zoom.canvas2.positionY}
										onTransformChange={(transform) => handleTransformChange('canvas2', transform)}
										isLocked={isLocked}
									/>}
								</div>
							</div>

							<div className="controls-section">
								<button className="removeButton" onClick={handleRemovePDF2}>
									Retirer Document
								</button>

								{pageCount2 > 0 && (
									<PageSelect
										pageCount={pageCount2}
										selectedPage={selectedPage2}
										onPageChange={setSelectedPage2}
									/>
								)}
								<LayerList
									layers={layerConfigs2}
									selectedLayers={selectedLayers2}
									onLayerToggle={handleLayerToggle2}
									onLayersChange={setSelectedLayers2}
									showLayers={!!pdfFile2}
								/>

								<div className="zoom-controls">
									<button className="zoom-button" onClick={() => handleZoomIn('canvas2')}>
										<img src={zoom_icon} alt="Zoom in" />
									</button>
									<button className="zoom-button" onClick={() => handleZoomOut('canvas2')}>
										<img src={dezoom_icon} alt="Zoom out" />
									</button>
								</div>
							</div>
						</div>
					)}
				</div>

				{pdfFile1 && pdfFile2 && <SharedToolbar
					option={option}
					onOptionChange={handleOptionChange}
					onClear={handleClear}
					isLocked={isLocked}
					onToggleLock={handleToggleLock}
					onResetZoom={handleResetZoom}
				/>}
			</div>

			{/* Boutons d'action */}
			{pdfFile1 && pdfFile2 && <button className="compare" onClick={handleCompareAndGrade}>
				LANCER
			</button>}

			<canvas id="canvasOutput" style={{ display: "none" }}></canvas>

			{/* Résultats de comparaison */}
			<div className="comparison-results">
				{isLoading ? (
					<LoadingOverlay progress={progress} />
				) : (
					<>
						{(showComparison || barcodeResult) && (
							<>
								{/* Titre pour le code-barres */}
								{barcodeGrades && barcodeResult && (
									<>
										<h2 className="title">Résultat code-barres</h2>
										<div className="title-bar"></div>
										<div className="comparison-grades">
												<BarcodeGrade grade={barcodeGrades.grade1} number={barcodeGrades.barcode1} />
											<div style={{ visibility: "hidden" }}>
												{" "}
												{/* Spacer */}
												<BarcodeGrade grade={null} />
											</div>
												<BarcodeGrade grade={barcodeGrades.grade2} number={barcodeGrades.barcode2} />
										</div>

										<div className="image-titles">
											<p>{truncateFileName(pdfFile1.name)}</p> {/* Nom du premier fichier */}
											<p>Superposition</p>             {/* Texte "Superposition" */}
											<p>{truncateFileName(pdfFile2.name)}</p> {/* Nom du deuxième fichier */}
										</div>

										{/* Ajoute le viewer de comparaison de code-barres ici */}
										<SynchronizedImageViewer
											images={[
												barcodeResult.img1,
												barcodeResult.errorsRGBUrl,
												barcodeResult.img2
											]}
										/>
									</>
								)}

								{/* Vérifie s'il existe des résultats de comparaison non-barcode */}
								{comparisonResults && comparisonResults.length > 0 && (
									<>
										{/* Réaffichage du titre pour les comparaisons multiples */}
										<h2 className="title">Résultat comparaison</h2>
										<div className="title-bar"></div>

										{comparisonResults.map((result, index) => (
											<div key={index} className="comparison-row">
												{/* Affichage des sous-titres pour les zones de comparaison multiples */}
												{!result.isSingleLargeRectangle && (
													<h3 className="sub-title">Zone {index + 1}</h3>
												)}

												{/* Comparaison avec une seule grande comparaison */}
												{result.isSingleLargeRectangle ? (
													<>
														<div className="image-titles">
															<p>{truncateFileName(pdfFile1.name)}</p>     {/* Nom du premier fichier */}
															<p>Superposition</p>          {/* Texte "Superposition" */}
															<p>{truncateFileName(pdfFile2.name)}</p>     {/* Nom du deuxième fichier */}
														</div>
														<SynchronizedImageViewer
															images={[result.img1, result.errorsRGBUrl, result.img2]}
														/>

														{result.isSingleLargeRectangle && result.tiles.length > 0 && (
															<>
																<h2 className="title">Zoom sur les anomalies</h2>
																<div className="title-bar"></div>
															</>
														)}

														{/* Affichage des tiles et de leurs boutons d'annotation */}
														{result.tiles.map((tile, tileIndex) => (
															<div key={`${index}-${tileIndex}`} className="tile-container">
																<h3 className="sub-title">Anomalie {tileIndex + 1}</h3>
																<div className="image-titles">
																	<p>{truncateFileName(pdfFile1.name)}</p>     {/* Nom du premier fichier */}
																	<p>Superposition</p>          {/* Texte "Superposition" */}
																	<p>{truncateFileName(pdfFile2.name)}</p>     {/* Nom du deuxième fichier */}
																</div>

																{/* Application d'une classe CSS dynamique selon la couleur de la bordure */}
																<div
																	className={`tiles-row ${borderColors[`${index}-${tileIndex}`] ? "tile-bordered" : ""}`}
																	style={{
																		borderColor:
																			borderColors[`${index}-${tileIndex}`] || "black" // Par défaut noire si non définie
																	}}
																>
																	<div className="tile-image-container">
																		<img
																			src={`data:image/png;base64,${tile.image1_tile}`}
																			alt={`Img 1 - ${tileIndex}`}
																		/>
																	</div>
																	<div className="tile-image-container central-image">
																		<img
																			src={`data:image/png;base64,${tile.tile}`}
																			alt={`Error Tile - ${tileIndex}`}
																		/>
																	</div>
																	<div className="tile-image-container">
																		<img
																			src={`data:image/png;base64,${tile.transformed_image2_tile}`}
																			alt={`Img 2 - ${tileIndex}`}
																		/>
																	</div>
																</div>

																{/* Boutons d'annotation pour les tiles */}
																<div className="annotation-buttons">
																	<button
																		onClick={() => handleAnnotation(index, tileIndex, "ignored")}
																		className="annotation-button ignore"
																	>
																		Ignorer
																	</button>
																	<button
																		onClick={() => handleAnnotation(index, tileIndex, "acceptable")}
																		className="annotation-button acceptable"
																	>
																		Anomalie acceptable
																	</button>
																	<button
																		onClick={() =>
																			handleAnnotation(index, tileIndex, "non-acceptable")
																		}
																		className="annotation-button non-acceptable"
																	>
																		Anomalie non-acceptable
																	</button>
																</div>
															</div>
														))}
													</>
												) : (
													<>
														{/* Comparaison multiple, sans affichage des tiles */}
														<div
															className={`image-border-wrapper ${borderColors[`${index}-0`] ? "tile-bordered" : ""}`} // Ajout de la classe dynamique
															style={{
																borderColor:
																	borderColors[`${index}-0`] ||
																	(result.tiles.length === 0 ? "green" : "transparent"), // Bordure verte par défaut si pas de tiles
																borderWidth: "5px",
																padding: "10px",
																marginBottom: "20px"
															}}
														>
															<SynchronizedImageViewer
																images={[result.img1, result.errorsRGBUrl, result.img2]}
															/>
														</div>

														{/* Boutons d'annotation pour les comparaisons multiples */}
														<div className="annotation-buttons">
															<button
																onClick={() => handleAnnotation(index, 0, "ignored")}
																className="annotation-button ignore"
															>
																Ignorer
															</button>
															<button
																onClick={() => handleAnnotation(index, 0, "acceptable")}
																className="annotation-button acceptable"
															>
																Anomalie acceptable
															</button>
															<button
																onClick={() => handleAnnotation(index, 0, "non-acceptable")}
																className="annotation-button non-acceptable"
															>
																Anomalie non-acceptable
															</button>
														</div>
													</>
												)}
											</div>
										))}
									</>
								)}
								<div className='generatePDF'>
										<div className="pdf-input-container">
											<input
												className="PdfFileName"
												type="text"
												placeholder="Entrez le code produit"
												value={PDFfileName}
												onChange={(e) => setPDFfileName(e.target.value)}
											/>
											<span className={`input-error-message ${(!PDFfileName || !areAllResultsAnnotated()) && 'visible'}`}>
												{!areAllResultsAnnotated() ? "Veuillez annoter tous les résultats" :
													!PDFfileName ?
													"Veuillez entrer un code produit" 
													: "Générer le rapport"}
											</span>
										</div>

										<button
											className="pdfbutton"
											onClick={generatePDF}
											disabled={!PDFfileName || !areAllResultsAnnotated()}
										>
											GENERER RAPPORT
										</button>
								</div>
							</>
						)}
					</>
				)}
			</div>
			<Modal
				isOpen={showErrorModal}
				onClose={() => setShowErrorModal(false)}
			>
				<p>Nous avons rencontré une erreur technique, merci de contacter le support technique ou de réessayer.</p>
			</Modal>
		</div>)
};

export default DisplayPDF; 