import React from 'react';
import './ProgressBar.css'

const ProgressBar = ({ progress }) => {
	return (
		<div className="progress-container">
			<div
				className="progress-bar"
				style={{ width: `${progress}%` }}
			></div>
			<div className="progress-text">{Math.round(progress)}%</div>
		</div>
	);
};

export default ProgressBar;
