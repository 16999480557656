import React, { useEffect, useState } from 'react';
import './LayerList.css'
import { HiChevronDown } from 'react-icons/hi';

const LayerList = ({ layers, selectedLayers, onLayerToggle, onLayersChange, showLayers }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		onLayersChange(selectedLayers);
	}, [selectedLayers, onLayersChange]);

	if (!showLayers) {
		return null;
	}

	return (
		<div className="layer-list">
			<div
				className="layer-header"
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<label className='calque'>CALQUES</label>
				<HiChevronDown
					className={`expand-icon ${isExpanded ? 'expanded' : ''}`}
					size={20}
				/>
			</div>
			<div className={`layer-content ${isExpanded ? 'expanded' : ''}`}>
				{layers.length > 0 ? (
					<ul>
						{layers.map((layer, i) => (
							<li key={layer.text + i}>
								<label>
									<input
										type="checkbox"
										checked={!selectedLayers.includes(layer.text)}
										onChange={() => onLayerToggle(layer.text)}
									/>
									{layer.text}
								</label>
							</li>
						))}
					</ul>
				) : (
					<p>Aucun calque détecté</p>
				)}
			</div>
		</div>
	);
};

export default LayerList;
