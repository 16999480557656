import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import './LoadingOverlay.css';

const LoadingOverlay = ({ progress }) => {
	return (
		<div className="loading-overlay">
			<div className="loading-content">
				<h2>Traitement en cours...</h2>
				<ProgressBar progress={progress} />
			</div>
		</div>
	);
};

export default LoadingOverlay;