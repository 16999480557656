import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import DisplayPDF from './components/DisplayPDF/DisplayPDF';

function App() {
	return (
		<Router>
			<div className="App">
				<Header />
				<main>
					<Routes>
						<Route path="/" element={<Navigate to="/equipe-idp" />} />
						<Route path="/equipe-idp" element={<DisplayPDF key="idp" mode="IDP" />} />
						<Route path="/equipe-pep" element={<DisplayPDF key="pep" mode="PEP" />} />
					</Routes>
				</main>
			</div>
		</Router>
	);
}

export default App;