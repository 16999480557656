import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './FileDropZone.css';

const FileDropzone = ({ onDrop, label }) => {

	const onDropCallback = useCallback((acceptedFiles) => {
		onDrop(acceptedFiles[0]);
	}, [onDrop]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropCallback });

	return (
		<div {...getRootProps()} className="dropzone" role="presentation">
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the file here...</p>
			) : (
				<div>
					<p className="dropzone-label">{label}</p>
					<p>Glisser et Déposer</p>
					<p>ou</p>
					<p>Téléverser</p>
					<p>PDF, max. 200MB</p>
				</div>
			)}
		</div>
	);
};

export default FileDropzone;
